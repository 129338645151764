/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values */
/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage */
/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop */
import React, { useContext, useState } from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import type {
	BrowserConfig,
	DropzoneConfig,
	UploadEndEventPayload,
	UploadsStartEventPayload,
} from '@atlaskit/media-picker';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { Browser, Dropzone } from '@atlaskit/media-picker';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { token } from '@atlaskit/tokens';
import { CustomThemeButton } from '@atlaskit/button';
import { MediaClient } from '@atlaskit/media-client';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ImportContentWorkflowContext } from '../ImportContentStore';

import { createAuthProvider } from './authProviderUtils';
import { UploadFileStatusDisplay } from './UploadFileStatusDisplay';

const i18n = defineMessages({
	zipSelection: {
		id: 'import-content.upload-file-dropzone.zip-selection',
		defaultMessage: 'Select a ZIP file to upload',
		description:
			'Text in the center of a dropzone instructing the user to select a ZIP file from their computer',
	},
	orDragAndDrop: {
		id: 'import-content.upload-file-dropzone.or-drag-and-drop',
		defaultMessage: 'or drag and drop here',
		description:
			'Text underneath import-content.upload-file-dropzone.zip-selection providing the user an alternative to clicking the dropzone, which is to drag and drop a file directly on top of it',
	},
	unsupportedFileError: {
		id: 'import-content.upload-file-dropzone.unsupported-file-error',
		defaultMessage: 'This file is not supported. Try uploading a ZIP file.',
		description:
			'Error message indicating that the selected file type a user tried to upload is invalid, and that they need to upload a ZIP file instead.',
	},
	tooManyFilesError: {
		id: 'import-content.upload-file-dropzone.too-many-files-error',
		defaultMessage: 'We can only upload 1 zip file at a time',
		description:
			'Error message indicating that the user attempted to upload more than 1 zip file, when we only support 1 at a time',
	},
	fileTooLargeError: {
		id: 'import-content.upload-file-dropzone.too-big-file-error',
		defaultMessage:
			'This file exceeds the attachment limit. Go to {confluenceSettingsLink}, increase the limit, and retry the import.',
		description:
			'Error message indicating that the user attempted to upload a file that is too large for the current attachment size limit',
	},
	confluenceSettingsLinkText: {
		id: 'import-content.upload-file-dropzone.confluence-settings-link-text',
		defaultMessage: 'Confluence settings',
		description: 'Text indicating where attachment size settings can be changed',
	},
	uploadFileError: {
		id: 'import-content.upload-file-dropzone.upload-file-error',
		defaultMessage: 'Error uploading file, please try again',
		description: 'Error message indicating that there is an error uploading the file',
	},
});

type UploadFileDropzoneProps = {
	contentId: string;
	isNotion: boolean;
};

export const UploadFileDropzone = ({ contentId, isNotion }: UploadFileDropzoneProps) => {
	const context = useContext(ImportContentWorkflowContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [uploadFileValidationError, setUploadFileValidationError] = useState<React.ReactNode>('');
	const { formatMessage } = useIntl();

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	const DropzoneHeading = styled.div({
		color: token('color.text', 'rgba(23, 43, 77, 1)'),
		fontSize: '14px',
		fontWeight: 600,
		lineHeight: '16px',
	});

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	const SubDescription = styled.div({
		color: token('color.text.subtle', 'rgba(68, 84, 111, 1)'),
		fontWeight: 400,
		lineHeight: '20px',
		fontSize: '14px',
	});

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	const UploadErrorMessage = styled.div({
		color: token('color.text.danger', '#AE2E24'),
		lineHeight: '14px',
		fontSize: '11px',
	});

	const mediaClient = new MediaClient({
		authProvider: createAuthProvider(contentId, context.setAuthProviderError),
		useSha256ForUploads: true,
	});

	const cancelUpload = () => {
		context.dropzoneCancelFnRef.current && context.dropzoneCancelFnRef.current();
		context.browserCancelFnRef.current && context.browserCancelFnRef.current();
	};

	const resetUploadStatus = () => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'uploadCancelled',
				actionSubject: 'resetUploadStatus',
				source: 'importContentUploadFileDropzone',
			},
		}).fire();
		cancelUpload();
		context.setIsUploadingFile(false);
		context.setFilestoreId('');
		context.setFileSizeBytes(0);
		context.setFileNameDisplay('');
		setUploadFileValidationError('');
	};

	const onEnd = (payload: UploadEndEventPayload) => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'uploadEnded',
				actionSubject: 'onEnd',
				source: 'importContentUploadFileDropzone',
			},
		}).fire();
		context.setIsUploadingFile(false);
		context.setIsStartingImport(true);
		context.setFilestoreId(payload.file.id);
	};

	const onOpen = () => {
		if (uploadFileValidationError) {
			setUploadFileValidationError('');
		}
		setShowDialog(true);
		context.setIsUploadingFile(false);
		context.setFilestoreId('');
	};

	const onDrop = () => {
		if (uploadFileValidationError) {
			setUploadFileValidationError('');
		}
		context.setFilestoreId('');
	};

	const onUploadsStart = (payload: UploadsStartEventPayload) => {
		// if file size is bigger than the attachment limit, then the list is empty here
		if (payload.files.length == 0) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'errored',
					actionSubject: 'onUploadsStart',
					source: 'importContentUploadFileDropzone',
					attributes: {
						error: 'File too large',
					},
				},
			}).fire();

			const message = formatMessage(i18n.fileTooLargeError, {
				confluenceSettingsLink: (
					// eslint-disable-next-line @atlaskit/design-system/no-html-anchor
					<a href="/wiki/admin/configuration" target="_blank">
						{formatMessage(i18n.confluenceSettingsLinkText)}
					</a>
				),
			});

			cancelUpload();
			setUploadFileValidationError(message);

			return;
		}

		if (payload.files.length > 1) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'errored',
					actionSubject: 'onUploadsStart',
					source: 'importContentUploadFileDropzone',
					attributes: {
						error: 'Too many files',
					},
				},
			}).fire();

			cancelUpload();
			setUploadFileValidationError(formatMessage(i18n.tooManyFilesError));

			return;
		}

		const mimeType = payload.files[0]?.type;
		const acceptedMimeTypes = ['application/zip', 'application/x-zip-compressed'];

		if (!acceptedMimeTypes.includes(mimeType)) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'errored',
					actionSubject: 'onUploadsStart',
					source: 'importContentUploadFileDropzone',
					attributes: {
						error: 'Unsupported file',
					},
				},
			}).fire();

			cancelUpload();
			setUploadFileValidationError(formatMessage(i18n.unsupportedFileError));

			return;
		}

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'uploadStarted',
				actionSubject: 'onUploadsStart',
				source: 'importContentUploadFileDropzone',
			},
		}).fire();

		context.setFileSizeBytes(payload.files[0]?.size);
		context.setFileNameDisplay(payload.files[0]?.name);
		context.setIsUploadingFile(true);
	};

	const browseConfig: BrowserConfig = {
		multiple: false,
		fileExtensions: ['.zip'],
		uploadParams: {
			collection: `contentId-${contentId}`,
		},
	};

	const getConfig = () => {
		const config: DropzoneConfig = {
			container: document.getElementById('dropzone')!,
			uploadParams: {
				collection: `contentId-${contentId}`,
			},
		};
		return config;
	};

	if (context.authProviderError) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'errored',
				actionSubject: 'getMediaToken',
				source: 'importContentUploadFileDropzone',
				attributes: {
					error: context.authProviderError,
				},
			},
		}).fire();

		return <FormattedMessage {...i18n.uploadFileError} />;
	}

	if (context.isUploadingFile || (context.filestoreId && uploadFileValidationError === '')) {
		return (
			<UploadFileStatusDisplay
				resetUploadStatus={resetUploadStatus}
				fileNameDisplay={context.fileNameDisplay}
				isSuccessful={!!context.filestoreId && uploadFileValidationError === ''}
				isNotion={isNotion}
			/>
		);
	}

	return (
		<Box>
			<CustomThemeButton
				style={{
					height: '200px',
					width: '100%',
					border: !!uploadFileValidationError
						? `2px dashed ${token('color.border.danger', '#E2483D')}`
						: `1px dashed ${token('color.border.bold', '#758195')}`,
					backgroundColor: `${token('elevation.surface.raised')}`,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				onClick={onOpen}
			>
				<Inline alignInline="center" alignBlock="center" space="space.200">
					<Box
						xcss={xcss({
							padding: 'space.100',
							backgroundColor: 'color.background.neutral',
							lineHeight: '0',
							borderRadius: '3px',
						})}
					>
						<UploadIcon size="small" label="Upload icon" />
					</Box>
					<Stack alignInline="start" space="space.050">
						<DropzoneHeading>
							<FormattedMessage {...i18n.zipSelection} />
						</DropzoneHeading>
						<SubDescription>
							<FormattedMessage {...i18n.orDragAndDrop} />
						</SubDescription>
					</Stack>
				</Inline>
				{mediaClient && (
					<>
						<Dropzone
							mediaClientConfig={mediaClient.config}
							config={getConfig()}
							onEnd={onEnd}
							onDrop={onDrop}
							onUploadsStart={onUploadsStart}
							onCancelFn={(cancel: any) => (context.dropzoneCancelFnRef.current = cancel)}
							onError={(err) => {
								context.setAuthProviderError(err);
							}}
						/>
						<Browser
							isOpen={showDialog}
							mediaClientConfig={mediaClient.config}
							config={browseConfig}
							onClose={() => setShowDialog(false)}
							onUploadsStart={onUploadsStart}
							onEnd={onEnd}
							onCancelFn={(cancel: any) => (context.browserCancelFnRef.current = cancel)}
							onError={(err) => {
								context.setAuthProviderError(err);
							}}
						/>
					</>
				)}
			</CustomThemeButton>

			{!!uploadFileValidationError && (
				<Inline
					space="space.050"
					xcss={xcss({
						marginTop: 'space.100',
					})}
				>
					<ErrorIcon
						size="small"
						label="error icon for upload file errors"
						primaryColor={token('color.text.danger', '#AE2E24')}
					/>
					<UploadErrorMessage>{uploadFileValidationError}</UploadErrorMessage>
				</Inline>
			)}
		</Box>
	);
};
